import i18n from '@/i18n/i18n'
import Vue from 'vue'
import VueRouter from 'vue-router'
import authService from '../auth/authService.js'
import store from '../store/index.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [

    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        requiresAuth: true
      }
    },

    /* USER SCHOOL */
    {
    path: '/researchPapers',
    name: 'school-research-papers',
    component: () => import('@/views/paper/sola/SchoolPaperList.vue'),
    meta: {
      pageTitle: i18n.t('general.papers_list'),
      requiresAuth: true
    }
  },
    {
      path: '/researchPapers/:id',
      name: 'research-paper',
      component: () => import('@/views/paper/Paper.vue'),
      meta: {
        pageTitle: i18n.t('general.paper'),
        requiresAuth: true
      }
    },
    {
      path: '/researchPapers/:id/edit',
      name: 'edit-paper',
      component: () => import('@/views/paper/sola/EditPaper.vue'),
      meta: {
        pageTitle: i18n.t('general.edit_paper'),
        requiresAuth: true
      }
    },
    {
      path: '/researchPaper/add',
      name: 'add-research-paper',
      component: () => import('@/views/paper/sola/AddPaper.vue'),
      meta: {
        pageTitle: i18n.t('general.add_paper'),
        requiresAuth: true
      }
    },

    // MAYBE DELETE THIS
    // {
    //   path: '/school/',
    //   name: 'school',
    //   component: () => import('@/views/school/solaUser/School.vue'),
    //   meta: {
    //     pageTitle: i18n.t('general.school'),
    //     requiresAuth: true
    //   }
    // },
    {
      path: '/school/add/menthor',
      name: 'add-menthor',
      component: () => import('@/views/menthor/AddMenthor.vue'),
      meta: {
        pageTitle: i18n.t('general.add_menthor'),
        requiresAuth: true
      }
    },
    {
      path: '/school/add/author',
      name: 'add-author',
      component: () => import('@/views/author/school/AddAuthor.vue'),
      meta: {
        pageTitle: i18n.t('general.add_author'),
        requiresAuth: true
      }
    },
    {
      path: '/menthors',
      name: 'menthors',
      component: () => import('@/views/menthor/Mentorji.vue'),
      meta: {
        pageTitle: i18n.t('general.menthors'),
        requiresAuth: true
      }
    },
    
    {
      path: '/menthor/:id/edit',
      name: 'edit-menthor',
      component: () => import('@/views/menthor/EditMenthor.vue'),
      meta: {
        pageTitle: i18n.t('general.edit_menthor'),
        requiresAuth: true
      }
    },

    {
      path: '/authors',
      name: 'authors',
      component: () => import('@/views/author/school/Avtorji.vue'),
      meta: {
        pageTitle: i18n.t('general.authors'),
        requiresAuth: true
      }
    },
    {
      path: '/author/:id/edit',
      name: 'edit-author',
      component: () => import('@/views/author/school/EditAuthor.vue'),
      meta: {
        pageTitle: i18n.t('general.edit_author'),
        requiresAuth: true
      }
    },


    /* ADMIN */
    {
      path: '/admin/researchPapers',
      name: 'admin-research-papers',
      component: () => import('@/views/paper/admin/AdminPaperList.vue'),
      meta: {
        pageTitle: i18n.t('general.papers_list'),
        requiresAuth: true
      }
    },
    {
      path: '/admin/researchPapers/:id',
      name: 'admin-research-paper',
      component: () => import('@/views/paper/admin/Paper.vue'),
      meta: {
        pageTitle: i18n.t('general.paper'),
        requiresAuth: true
      }
    },
    {
      path: '/admin/researchPapers/:id/edit',
      name: 'admin-edit-paper',
      component: () => import('@/views/paper/admin/EditPaper.vue'),
      meta: {
        pageTitle: i18n.t('general.papers_list'),
        requiresAuth: true
      }
    },

    {
      path: '/admin/schools',
      name: 'school-list',
      component: () => import('@/views/school/SchoolList.vue'),
      meta: {
        pageTitle: i18n.t('general.school_list'),
        requiresAuth: true
      }
    },
    {
      path: '/admin/school/:school_id/edit',
      name: 'edit-school',
      component: () => import('@/views/school/EditSchool.vue'),
      meta: {
        pageTitle: i18n.t('general.edit_school'),
        requiresAuth: true
      }
    },

    {
      path: '/admin/school/add',
      name: 'add-school',
      component: () => import('@/views/school/AddSchool.vue'),
      meta: {
        pageTitle: i18n.t('general.add_school'),
        requiresAuth: true
      }
    },
    {
      path: '/admin/school-year',
      name: 'school-year',
      component: () => import('@/views/schoolYear/SchoolYear.vue'),
      meta: {
        pageTitle: i18n.t('general.school_year'),
        requiresAuth: true
      }
    },
    {
      path: '/admin/school-year/add',
      name: 'school-year-add',
      component: () => import('@/views/schoolYear/AddSchoolYear'),
      meta: {
        pageTitle: i18n.t('general.school_year'),
        requiresAuth: true
      }
    },
    {
      path: '/admin/school-year/:school_year_id/edit',
      name: 'school-year-edit',
      component: () => import('@/views/schoolYear/EditSchoolYear.vue'),
      meta: {
        pageTitle: i18n.t('general.school_year'),
        requiresAuth: true
      }
    },
    {
      path: '/admin/field-list',
      name: 'field-list',
      component: () => import('@/views/fields/FieldList'),
      meta: {
        pageTitle: i18n.t('general.field_list'),
        requiresAuth: true
      }
    },
    {
      path: '/admin/field-list/add',
      name: 'add-field',
      component: () => import('@/views/fields/AddField'),
      meta: {
        pageTitle: i18n.t('general.add_field'),
        requiresAuth: true
      }
    },
    {
      path: '/admin/field-list/:field_id/edit',
      name: 'edit-field',
      component: () => import('@/views/fields/EditField'),
      meta: {
        pageTitle: i18n.t('general.edit_field'),
        requiresAuth: true
      }
    },

    {
      path: '/admin/authors',
      name: 'admin-authors',
      component: () => import('@/views/author/admin/Avtorji.vue'),
      meta: {
        pageTitle: i18n.t('general.authors'),
        requiresAuth: true
      }
    },
    {
      path: '/admin/author/:author_id/edit',
      name: 'admin-edit-author',
      component: () => import('@/views/author/admin/EditAuthor.vue'),
      meta: {
        pageTitle: i18n.t('general.edit_author'),
        requiresAuth: true
      }
    },
    {
      path: '/admin/sola/:school_id/author/add',
      name: 'admin-add-author',
      component: () => import('@/views/author/admin/AddAuthor.vue'),
      meta: {
        pageTitle: i18n.t('general.add_author'),
        requiresAuth: true
      }
    },

    {
      path: '/admin/menthors',
      name: 'admin-menthors',
      component: () => import('@/views/menthor/admin/Mentorji.vue'),
      meta: {
        pageTitle: i18n.t('general.menthors'),
        requiresAuth: true
      }
    },
    {
      path: '/admin/menthor/:menthor_id/edit',
      name: 'admin-edit-menthor',
      component: () => import('@/views/menthor/admin/EditMenthor.vue'),
      meta: {
        pageTitle: i18n.t('general.edit_menthor'),
        requiresAuth: true
      }
    },
    {
      path: '/admin/sola/:school_id/menthor/add',
      name: 'admin-add-menthor',
      component: () => import('@/views/menthor/admin/AddMenthor.vue'),
      meta: {
        pageTitle: i18n.t('general.add_menthor'),
        requiresAuth: true
      }
    },


    // {
    //   path: '/schoolList',
    //   name: 'school-list',
    //   component: () => import('@/views/school/SchoolList.vue'),
    //   meta: {
    //     pageTitle: i18n.t('general.school_list'),
    //     requiresAuth: true
    //   }
    // },
    // {
    //   path: '/school/:school_id',
    //   name: 'school',
    //   component: () => import('@/views/school/solaUser/School.vue'),
    //   meta: {
    //     pageTitle: i18n.t('general.school'),
    //     requiresAuth: true
    //   }
    // },
    // {
    //   path: '/school/add',
    //   name: 'add-school',
    //   component: () => import('@/views/school/AddSchool.vue'),
    //   meta: {
    //     pageTitle: i18n.t('general.add_school'),
    //     requiresAuth: true
    //   }
    // },
    // {
    //   path: '/school/:school_id/edit',
    //   name: 'edit-school',
    //   component: () => import('@/views/school/EditSchool.vue'),
    //   meta: {
    //     pageTitle: i18n.t('general.edit_school'),
    //     requiresAuth: true
    //   }
    // },

    // {
    //   path: '/school/:school_id/authors',
    //   name: 'school-authors',
    //   component: () => import('@/views/author/AuthorListCard.vue'),
    //   meta: {
    //     pageTitle: i18n.t('general.authors'),
    //     requiresAuth: true
    //   }
    // },
    // {
    //   path: '/school/:school_id/authors/add',
    //   name: 'add-author',
    //   component: () => import('@/views/author/AddAuthor.vue'),
    //   meta: {
    //     pageTitle: i18n.t('general.add_author'),
    //     requiresAuth: true
    //   }
    // },
    // {
    //   path: '/school/:school_id/authors/:author_id/edit/',
    //   name: 'edit-author',
    //   component: () => import('@/views/author/EditAuthor.vue'),
    //   meta: {
    //     pageTitle: i18n.t('general.edit_author'),
    //     requiresAuth: true
    //   }
    // },

    // {
    //   path: '/school/:school_id/menthors',
    //   name: 'school-menthors',
    //   component: () => import('@/views/menthor/MenthorListCard.vue'),
    //   meta: {
    //     pageTitle: i18n.t('general.menthors'),
    //     requiresAuth: true
    //   }
    // },
    // {
    //   path: '/school/:school_id/menthors/add',
    //   name: 'add-menthor',
    //   component: () => import('@/views/menthor/EditMenthor.vue'),
    //   meta: {
    //     pageTitle: i18n.t('general.add_menthor'),
    //     requiresAuth: true
    //   }
    // },
    // {
    //   path: '/school/:school_id/menthors/:menthor_id/edit/',
    //   name: 'edit-menthor',
    //   component: () => import('@/views/menthor/EditMenthor.vue'),
    //   meta: {
    //     pageTitle: i18n.t('general.edit_menthor'),
    //     requiresAuth: true
    //   }
    // },
     
    
    {
      path: '/faq',
      name: 'faq',
      component: () => import('@/views/FAQ.vue'),
      meta: {
        pageTitle: i18n.t('general.faq'),
        requiresAuth: true
      }
    },
    

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

router.beforeEach((to, from, next) => {

  const loggedIn = localStorage.getItem('loggedIn')
  if (loggedIn) {
    store.state.user.loggedIn = true
    const userData = localStorage.getItem('userData')
    if (userData) {
      store.state.user.userData = JSON.parse(userData)
    }
  }

  if (to.meta.requiresAuth && !authService.isAuthenticated()) {
      return next('/login')
  }

  if (to.meta.roles) {
    if (to.meta.roles.includes(store.state.user.userData.type)) {
      return next()
    } else {
      return next('/login')
    }

  }

  next()
})

export default router
