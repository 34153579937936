export default {
  sl: {
    general: {
      
      profile: 'Profil',
      home: 'Domov',
      secondPage: 'Druga stran',
      search_papers: 'Išči po nalogah',
      papers_list: 'Seznam nalog',
      papers_list_prijavljeno: 'Seznam prijavljenih nalog',
      papers_list_submit: 'Seznam oddanih nalog',
      school_year: 'Šolsko leto',
      field_list: 'Področja',
      school: 'Šola',
      schools: 'Šole',
      status: 'Stanje',
      status_registered: 'Prijavljen',
      status_submited: 'Oddana',
      search: 'Išči',
      number_of_rows: 'Število zadetkov',
      per_page: 'Na stran',
      of: 'od',
      no: 'Ne',
      yes: 'Da',
      edit: 'Uredi',
      delete: 'Izbriši',
      for_school: 'za šolo',
      select_state_warning: 'Prosimo izberite status nalog!',

      type: 'Vrsta',
      type_raziskovalna: 'Raziskovalna naloga',
      type_inovative: 'Inovacijski predlog',
      area: 'Področje',
      areas: 'Področja',
      interdisciplinary: 'Interdisciplinarno',
      interdisciplinary_two_areas_selected: 'Pri interdisciplinarni nalogi morata biti izbrana vsaj dva področja!',
      paper: 'Naloga',

      add_paper: 'Dodaj nalogo',
      edit_paper: 'Uredi nalogo',
      add_pdf: 'Dodaj PDF',
      title: 'Naslov',
      subtitle: 'Podnaslov',
      schoolForm: 'Odpri prijavnico',
      number_of_points: 'Število točk',
      reached_place: 'Doseženo mesto',
      place: 'Mesto',
      award: 'Priznanje',
      authors: 'Avtorji',
      menthors: 'Mentorji',
      name: 'Ime',
      lastname: 'Priimek',
      profTitle: 'Strokovni naziv',
      submit: 'Oddaj',
      summary: 'Povzetek',
      birthday: 'Datum rojstva',
      save: 'Shrani',
      add: 'Dodaj',
      help: 'Pomoč',
      go_back: 'Pojdi nazaj',
      more_details: 'Več podrobnosti',
      set_points: 'Vpiši točke',
      emailAddress: 'E-Pošta',

      submit_paper: 'Oddaj nalogo',
      paper_submited_successfully: 'Naloga je bila uspešno oddana!',
      paper_submit_error: 'Pri oddajanju naloge je prišlo do napake!',

      add_error: 'Prišlo je do napake pri dodajanju!',
      delete_error: 'Prišlo je do napake pri brisanju!',
      edit_error: 'Prišlo je do napake pri urejanju!',
      delete_paper_confirmation: 'Ali res želite izbrisati to nalogo?',
      paper_successfully_added: 'Naloga je bila uspešno dodana',
      paper_successfully_edited: 'Naloga je bila uspešno urejena',
      paper_successfully_deleted: 'Naloga je bila uspešno izbrisana',

      add_school: 'Dodaj šolo',
      edit_school: 'Uredi šolo',
      delete_school: 'Izbriši šolo',
      edit_user: 'Uredi uporabnika',
      update_password: 'Posodobi geslo',
      save_password: 'Shrani geslo',
      school_list: 'Seznam šol',
      school_title: 'Naziv',
      school_short: 'Kratica',
      school_type: 'Vrsta',
      school_user: 'Uporabnik',
      password: 'Geslo',
      repeat_password: 'Ponovi geslo',
      generate: 'Generiraj',
      school_active: 'Aktivna',
      delete_school_confirmation: 'Ali res želite izbrisati to šolo?',
      school_successfully_added: 'Šola je bila uspešno dodana',
      school_successfully_edited: 'Šola je bila uspešno urejena',
      school_successfully_deleted: 'Šola je bila uspešno izbrisana',
      password_successfully_updated: 'Geslo je bilo uspešno posodobljeno',
      primary_school: 'Osnovna šola',
      secondary_school: 'Srednja šola',
      active: 'Aktivna',

      add_author: 'Dodaj avtorja',
      add_author_for_selected_school: 'Dodaj avtorja izbrani šoli',
      edit_author: 'Uredi avtorja',
      author_list: 'Seznam avtorjev',
      delete_author_confirmation: 'Ali res želite izbrisati tega avtorja?',
      author_successfully_added: 'Avtor je bila uspešno dodan',
      author_successfully_edited: 'Avtor je bila uspešno urejen',
      author_successfully_deleted: 'Avtor je bila uspešno izbrisan',
      author_constraint_error: 'Avtor je določen pri vsaj eni nalogi, zato ne more biti izbrisan!',

      add_menthor: 'Dodaj mentorja',
      add_menthor_for_selected_school: 'Dodaj mentorja izbrani šoli',
      edit_menthor: 'Uredi mentorja',
      menthor_list: 'Seznam mentorjev',
      delete_menthor_confirmation: 'Ali res želite izbrisati tega mentorja?',
      menthor_successfully_added: 'Mentor je bil uspešno dodan',
      menthor_successfully_edited: 'Mentor je bil uspešno urejen',
      menthor_successfully_deleted: 'Mentor je bila uspešno izbrisan',
      menthor_constraint_error: 'Mentor je določen pri vsaj eni nalogi, zato ne more biti izbrisan!',

      load_error: 'Prišlo je do napake pri nalaganju!',
      faq: 'Pomoč',
      password_not_correct: 'Vpisano geslo je bilo napačno!',

      possibleRegister: 'Možnost prijave teme',
      possibleSubmit: 'Možnost oddaje teme',
      points: 'Točke',
      gold: 'Zlato',
      silver: 'Srebrno',
      bronze: 'Bronasto',
      file: 'Datoteka',

      schoolYearSuccess: 'Šolsko leto je bilo uspešno dodano',
      schoolYearEdited: 'Šolsko leto je bilo uspešno urejeno',
      schoolYear_successfully_deleted: 'Šolsko leto je bilo uspešno izbrisano',
      add_school_year: 'Dodaj šolsko leto',
      school_years: 'Šolska leta',
      edit_schoolYear: 'Uredi šolsko leto',
      delete_schoolYear: 'Izbriši šolsko leto',
      currentSchoolYear: 'Tekoče šolsko leto',
      delete_school_year_confirmation: 'Ali res želite izbrisati to šolsko leto?',

      add_field: 'Dodaj področje',
      edit_field: 'Uredi področje',
      delete_field: 'Izbriši področje',
      field_name: 'Ime',
      delete_field_confirmation: 'Ali res želite izbrisati to področje?',
      field_successfully_added: 'Področje je bilo uspešno dodano',
      field_successfully_edited: 'Področje je bilo uspešno urejeno',
      field_successfully_deleted: 'Področje je bilo uspešno izbrisano',

      results: 'Rezultati',
      papers_awards: 'Seznam priznanj',
      add_award: 'Dodaj priznanje',
      edit_award: 'Uredi priznanje',
      generate_results: 'Generiraj rezultate',
      result_successfully_generated: 'Rezultati so bili uspešno generirani',
      award_successfully_added: 'Priznanje je bila uspešno dodana',
      award_successfully_edited: 'Priznanje je bilo uspešno urejena',
      award_successfully_deleted: 'Priznanje je bilo uspešno izbrisana',
      delete_award_confirmation: 'Ali res želite izbrisati to priznanje?',
      awardOS: 'Priznanje osnovna šola',
      awardSS: 'Priznanje srednja šola'

    },
    login: {
      username: 'Uporabniško ime',
      greeting: 'Dobrodošli!',
      login: 'Prijavi se',
      rememberMe: 'Zapomni si me',
      password: 'Geslo',
      forgottenPassword: 'Pozabljeno geslo?',
      logout: 'Odjavi se'
    }
  }
}
