import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import router from './router'
import store from './store'
import App from './App.vue'
import i18n from './i18n/i18n'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'


// axios
import axios from './axios.js'

import { localize} from 'vee-validate'
localize('sl')

// ADDS BEARER JWT TO EVERY REQUEST
axios.interceptors.request.use(
  function(config) {
    const jwt = localStorage.getItem('accessToken')
    if (jwt !== '') config.headers.common['Authorization'] = `Bearer ${jwt}`

    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    if (error.response.status === 401) {
      router.push('/login')
    }
    return Promise.reject(error)
  }
)

Vue.prototype.$http = axios

//Moment.js
import moment from 'moment-timezone'
moment.tz.setDefault('SI')
Vue.prototype.moment = moment


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$printError = function(error) {
  this.$toast({
      component: ToastificationContent,
      props: {
          title: 'Napaka',
          icon: 'DangerOctagonIcon',
          text: error,
          variant: 'danger' 
      }
  })
}

Vue.prototype.$printWarning = function(message) {
  this.$toast({
      component: ToastificationContent,
      props: {
          title: 'Opozorilo',
          icon: 'AlertTriangleIcon',
          text: message,
          variant: 'warning'
      }
  })
}

Vue.prototype.$printSuccess = function(message) {
  this.$toast({
      component: ToastificationContent,
      props: {
          title: 'Uspešno',
          icon: 'CheckCircleIcon',
          text: message,
          variant: 'success'
      }
  })
}

Vue.prototype.$checkIfChangeIsPermitted = async() => {
  const response = await axios.get('/v1/sola/solskoLeto/')
  const years = response.data
  let selectedYear = null
  for (const year of years) {
    if (year.Active === 1) {
      selectedYear = year
    }
  }

  const today = moment()
  const startDateRegister = moment(selectedYear.StartDateRegister)
  const endDateRegister = moment(selectedYear.EndDateRegister).endOf('day')

  const startDateTurnIn = moment(selectedYear.StartDateTurnIn)
  const endDateTurnIn = moment(selectedYear.EndDateTurnIn).endOf('day')
  
  const isChangingPermitted = today.isBetween(startDateRegister, endDateRegister) || today.isBetween(startDateTurnIn, endDateTurnIn)

  return isChangingPermitted
}

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
